<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Multiselect from "vue-multiselect";
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import {formatDatepicker, formatNumber} from '@/utils/formatter'

export default {
  page: {
    title: "Billing Report",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { 
    DatePicker,
    Multiselect,
    Layout,
    PageHeader,
  },
  setup(){

    const { $get } = useHttp()

   const LOV = ref({
      ports: [],
      vendor:[],
      customer:[],
      dataExcel:[]
    })

   const form = ref({
      vendor:'',
      customer:'',
      ordDateFrom:'',
      ordDateThru:'',
      origin : '',
      destination : '',
      billing:[]
    })

    const paramdate = ref(['',''])
    const paramdeparture = ref(['',''])

    const getPorts = async () => {
      const {data} = await $get({ url: 'master/port'})
      LOV.value.ports = data
    }

    const getVendor = async () => { 
      const {data} = await $get({ url: 'master/vendor'})
      LOV.value.vendor = data
      }

    const getCustomer = async () => { 
      const {data} = await $get({ url: 'master/customers'})
      LOV.value.customer = data
      }

     const getBillinglist = async() =>{  
        let scheduleParams = {
          vendor: form.value.vendor.id,
          customer: form.value.customer.id,
          ordDateFrom : paramdate.value[0],
          ordDateThru : paramdate.value[1],
          origin: form.value.origin.prt_initial,
          destination : form.value.destination.prt_initial
        }
        const {data} = await $get({ url: 'master/report_billing', params: scheduleParams})
        form.value.billing = data
        LOV.value.dataExcel = data
    }

    const searchFlight = () =>{ 
      getBillinglist()
      // router.replace({ name: 'apps-report-list' })
    }

    onMounted(() => {
     getPorts()
     getVendor()
     getCustomer()
    })

    return {
      LOV,
      form,
      getPorts,
      getVendor,
      getCustomer,
      getBillinglist,
      searchFlight,
      formatNumber,
      formatDatepicker,
      paramdate,
      paramdeparture
    }
  },
 data() {
    return {
      title: "Billing Report",
      items: [],
      select_label: "Select"
    };
  },
  methods: {
    customLabel ({ prt_initial, prt_locationname }) {
      return `${prt_initial} – ${prt_locationname}`
    },
    printBilling() {
      window.print()
    },
    /*exportToExcel () {
      import('@/vendors/Export2Excel').then(excel => {
        const list = this.LOV.dataExcel

        // add title
        // const title = [this.$store.state.user.currentCompany.name, this.$t('apps.reportLedger.singular.ledgerReport'), this.periodReportText]
        const headerTitle = this.tableColumns.map(column => column.label)
        // only take indexData from columnDef,
        const headerVal = this.tableColumns.map(column => column.key)
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          multiHeader: [
            [this.$store.state.user.currentCompany.name, '', '', '', '', '', ''],
            ['Ledger Report', '', '', '', '', '', ''],
            [this.periodReportText, '', '', '', '', '', ''],
            ['', '', '', '', '', '', '']
          ],
          header: headerTitle,
          data,
          merges: [
            { s: { c: 0, r: 0 }, e: { c: 6, r: 0 } },
            { s: { c: 0, r: 1 }, e: { c: 6, r: 1 } },
            { s: { c: 0, r: 2 }, e: { c: 6, r: 2 } },
            { s: { c: 0, r: 3 }, e: { c: 6, r: 3 } }
          ],
          filename: `Ledger Report ${this.periodReportText}`,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    }*/
  }
};
</script>

<style lang="scss">
  .showonlyprint {
    display: none;
  }

  @media print {
    #app {
      * {
        visibility: hidden;
      }
      .showonlyprint {
        display: block;
      }
      .printwrap, .printwrap *  {
        visibility: visible;
      }
      .printwrap {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        padding: 27px 32px;
      }
    }
  }

  @page {
    size: auto;
  }
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
            <div class="col-lg-12">
             <form class="form-horizontal" role="form">
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Order Date"
                    label-for="order_date"
                  >
                    <date-picker  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                  </b-form-group>
                   <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Port Origin"
                    label-for="port_origin"
                  >
                    <multiselect id="mv_origin" name="mv_origin" :select-label="select_label" :custom-label="customLabel" v-model="form.origin" :options="LOV.ports"></multiselect>
                  </b-form-group>
                  <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Port Destination"
                    label-for="dest_origin"
                  >
                    <multiselect id="mv_destination" name="mv_destination" :select-label="select_label" :custom-label="customLabel" v-model="form.destination" :options="LOV.ports"></multiselect>
                  </b-form-group>

                 <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Airline"
                    label-for="vendor_id"
                    >
                    <multiselect v-model="form.vendor" :options="LOV.vendor" label="name" track-by="name" :select-label="select_label" ></multiselect>
                  </b-form-group>
                 <b-form-group
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Customer"
                    label-for="cust_id"
                    >
                    <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect>
                  </b-form-group>
                  </form>

                <div class="col-12 text-center">
                  <div class="button-items">
                    <b-button variant="primary" @click.prevent="searchFlight">
                      Search
                    </b-button>
                    <!-- <b-button variant="primary" @click.prevent="exportToExcel">
                    <i class="mdi mdi-file-download-outline align-middle"></i>
                      Export Excel
                    </b-button> -->
                  </div>
                </div>

              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-3">
              <div class="col-12 text-right">
                <b-button variant="primary" @click.prevent="printBilling">
                  <i class="ri-printer-line"></i>
                  Print
                </b-button>
              </div>
            </div>
            <div class="row printwrap mt-">
              <div class="col-12 text-center showonlyprint mb-2">
                Billing Report
              </div>
              <div class="col-12">
                <div class="table-responsive">
                  <table class="table font-size-13 table-bordered">
                    <thead>
                      <tr>
                        <th>No.</th>
                        <th>Tanggal</th>
                        <th>Customer</th>
                        <th>No. SMU</th>
                        <th>Services</th>
                        <th>Airlines</th>
                        <th>No. Flight</th>
                        <th>Port Asal</th>
                        <th>Port Tujuan</th>
                        <th>Jumlah KG</th>
                        <th>Jumlah Collies</th>
                        <th>Jenis Barang</th>
                        <th>Total Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data,index) in form.billing" :key="index">
                        <th scope="row">{{index+1}}</th>
                        <td>{{data.ord_datetime}}</td>
                        <td>{{data.cust_name}}</td>
                        <td>{{data.ord_manifestno}}</td>
                        <td>PTP</td>
                         <td class="text-center">{{data.vnd_name}}</td>
                        <td class="text-center">{{data.sch_flight_no}}</td>
                        <td class="text-center">{{data.oriprt_initial}}</td>
                        <td class="text-center">{{data.desprt_initial}}</td>
                        <td class="text-right">{{formatNumber(data.inv_weight)}}</td>
                        <td class="text-right">{{formatNumber(data.inv_collies)}}</td>
                        <td class="text-center">{{data.descr_en}}</td>
                         <td class="text-right">{{formatNumber(data.inv_gtotalcharge)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>